import { defineCustomElement } from 'vue'
import { LiveSwitchConcierge } from '@/scripts/LiveSwitchConcierge'
import styles from '@/styles/public.css?inline'
import icons from '@/styles/icons.css?inline'
import 'unfonts.css'
import { CustomElementTags } from './utility/Constants'
import { SentryFactory } from './utility/Sentry'
import { type Breadcrumb, type EventHint } from '@sentry/vue'
import { sentryTag } from './utility/Constants'

// Components
import BellPopupCe from '@/components/BellPopup.ce.vue'
import BellButtonCe from '@/components/BellButton.ce.vue'
import WindowPopupCe from '@/components/WindowPopup.ce.vue'
import DailingInCe from '@/components/DailingIn.ce.vue'
import WindowDecorationCe from '@/components/WindowDecoration.ce.vue'
import InCallCe from '@/components/InCall.ce.vue'
import EndCallCe from '@/components/EndCall.ce.vue'
import PermissionCheckCe from '@/components/PermissionCheck.ce.vue'
import KioskHomeCe from '@/components/KioskHome.ce.vue'
import FormCe from '@/components/InputForm.ce.vue'

const BellButton = defineCustomElement(BellButtonCe)
const BellPopup = defineCustomElement(BellPopupCe)
const WindowPopup = defineCustomElement(WindowPopupCe)
const DailingIn = defineCustomElement(DailingInCe)
const WindowDecoration = defineCustomElement(WindowDecorationCe)
const InCall = defineCustomElement(InCallCe)
const EndCall = defineCustomElement(EndCallCe)
const PermissionCheck = defineCustomElement(PermissionCheckCe)
const KioskHome = defineCustomElement(KioskHomeCe)
const Form = defineCustomElement(FormCe)

customElements.get(CustomElementTags.BELL) ||
  customElements.define(CustomElementTags.BELL, BellButton)
customElements.get(CustomElementTags.POPUP) ||
  customElements.define(CustomElementTags.POPUP, BellPopup)
customElements.get(CustomElementTags.WINDOW) ||
  customElements.define(CustomElementTags.WINDOW, WindowPopup)
customElements.get(CustomElementTags.DIALING_IN) ||
  customElements.define(CustomElementTags.DIALING_IN, DailingIn)
customElements.get(CustomElementTags.WINDOW_DECORATION) ||
  customElements.define(CustomElementTags.WINDOW_DECORATION, WindowDecoration)
customElements.get(CustomElementTags.IN_CALL) ||
  customElements.define(CustomElementTags.IN_CALL, InCall)
customElements.get(CustomElementTags.END_CALL) ||
  customElements.define(CustomElementTags.END_CALL, EndCall)
customElements.get(CustomElementTags.PERMISSIONS_CHECK) ||
  customElements.define(CustomElementTags.PERMISSIONS_CHECK, PermissionCheck)
customElements.get(CustomElementTags.KIOSK_HOME) ||
  customElements.define(CustomElementTags.KIOSK_HOME, KioskHome)
customElements.get(CustomElementTags.FORM) || customElements.define(CustomElementTags.FORM, Form)

const loadLiveSwitchStylesheets = () => {
  const url = import.meta.env.VITE_URL
  const inter = document.createElement('link')
  inter.rel = 'stylesheet'
  inter.href = 'https://rsms.me/inter/inter.css'
  document.head.appendChild(inter)
  const iconsSheet = document.createElement('style')
  iconsSheet.innerHTML = icons
  document.head.appendChild(iconsSheet)
  const stylesSheet = document.createElement('style')
  stylesSheet.innerHTML = styles
  document.head.appendChild(stylesSheet)
  const fonts = document.createElement('link')
  fonts.rel = 'stylesheet'
  fonts.href = `${url}/style.css`
  document.head.appendChild(fonts)
}

const globalScope = globalThis as any
export interface SentryScope {
  captureException: (e: unknown, hint?: EventHint) => void
  addBreadcrumb: (breadcrumb: Breadcrumb) => void
  setContext: (key: string, value: any) => void
  setTag: (key: string, value: any) => void
}
export const addBreadcrumb = (breadcrumb: any) => {
  if (globalScope.LSSentryScope && typeof globalScope.LSSentryScope.addBreadcrumb === 'function') {
    globalScope.LSSentryScope.addBreadcrumb(breadcrumb)
  }
}
export const setContext = (key: string, value: any) => {
  if (globalScope.LSSentryScope && typeof globalScope.LSSentryScope.setContext === 'function') {
    globalScope.LSSentryScope.setContext(key, value)
  }
}
export const setTag = (key: string, value: any) => {
  if (globalScope.LSSentryScope && typeof globalScope.LSSentryScope.setTag === 'function') {
    globalScope.LSSentryScope.setTag(key, value)
  }
}
export const captureException = (e: unknown, hint?: EventHint) => {
  if (
    globalScope.LSSentryScope &&
    typeof globalScope.LSSentryScope.captureException === 'function'
  ) {
    // Create a custom hint if not provided
    const customHint: EventHint = hint || {}

    // Ensure captureContext exists
    if (!customHint.captureContext) {
      customHint.captureContext = {}
    }

    // Add the custom tag
    customHint.captureContext = {
      ...customHint.captureContext,
      tags: {
        //@ts-expect-error Tags does exist, not sure why it doesn't find it. Remove in the future.
        ...(customHint.captureContext.tags || {}),
        source: sentryTag
      }
    }

    // Capture the exception with the custom hint
    globalScope.LSSentryScope.captureException(e, customHint)
  }
}
let retries = 0
// Initialization function
function initLiveSwitchConcierge() {
  const init = () => {
    const liveswitchConcierge = new LiveSwitchConcierge()
    globalScope.liveswitchConcierge = liveswitchConcierge
    globalScope.liveSwitchConcierge = liveswitchConcierge
    globalScope.LSSentryScope = SentryFactory.createSentry().init() as SentryScope
    addBreadcrumb({
      category: 'init',
      message: 'Started Init Processing.',
      level: 'info'
    })
    loadLiveSwitchStylesheets()
    const fn = (globalThis as any).liveswitchFn
    if (fn) {
      if (Array.isArray(fn)) {
        fn.forEach((method: (scope: LiveSwitchConcierge) => void) => {
          method.call(globalThis, liveswitchConcierge)
        })
        addBreadcrumb({
          category: 'init',
          message: 'Found and run LiveSwitch FN.',
          level: 'info'
        })
      }
      ;(globalThis as any).liveswitchFn = {
        push: (method: (scope: LiveSwitchConcierge) => void) => {
          method.call(globalThis, liveswitchConcierge)
          addBreadcrumb({
            category: 'init',
            message: 'Secondary LiveSwitch FN called.',
            level: 'info'
          })
        }
      }
    } else {
      ;(globalThis as any).liveswitchFn = {
        push: (method: (scope: LiveSwitchConcierge) => void) => {
          method.call(globalThis, liveswitchConcierge)
          addBreadcrumb({
            category: 'init',
            message: 'Secondary LiveSwitch FN called.',
            level: 'info'
          })
        }
      }
      try {
        if ((globalThis as any).liveSwitchConciergeCodeName) {
          liveswitchConcierge.loadBubble((globalThis as any).liveSwitchConciergeCodeName)
          addBreadcrumb({
            category: 'init',
            message: 'Ran load bubble method.',
            level: 'info'
          })
        }
      } catch (e) {
        captureException(e)
      }
    }

    const sb = (globalThis as any).sb
    if (sb && Array.isArray(sb.q) && sb.q.length === 1) {
      const n = sb.q[0]
      if (n[0] === 'init') {
        const args: LegacyInputArgs = n[1] as LegacyInputArgs
        liveswitchConcierge.loadBubble(args.id)
        addBreadcrumb({
          category: 'init',
          message: 'Called load bubble from old script.',
          level: 'info'
        })
      }
    }

    ;(globalThis as any).sb = (eventName: string, args: LegacyInputArgs) => {
      try {
        if (eventName !== 'init' || !args || !args.id) return
        liveswitchConcierge.loadBubble(args.id)
        addBreadcrumb({
          category: 'init',
          message: 'Called load bubble from old script using function.',
          level: 'info'
        })
      } catch (e) {
        captureException(e)
      }
    }
  }
  try {
    init()
  } catch (e) {
    retries++
    if (retries < 10) {
      setTimeout(initLiveSwitchConcierge, 100) // Retry after 100ms
    } else {
      captureException(e)
    }
  }
}

// Ensure the init function is called on document load or immediately if the script is added after the document is loaded
try {
  if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', initLiveSwitchConcierge)
  } else {
    initLiveSwitchConcierge()
  }
} catch (e) {
  captureException(e)
}

/* Legacy script tag section. Don't break. */
interface LegacyInputArgs {
  id: string
}

export {
  BellButton,
  BellPopup,
  WindowPopup,
  DailingIn,
  WindowDecoration,
  InCall,
  EndCall,
  PermissionCheck,
  KioskHome,
  Form
}
