import { captureException } from '@/bubble'
import type ResponseWithData from 'types/ResponseWithData'

export class API {
  baseURL: string = import.meta.env.VITE_API_URL
  errorMessages: Array<string> = []

  buildURL = (extra: string): string => {
    return this.baseURL + extra
  }

  call = async (url: string, method: string, params: any, data: any): Promise<ResponseWithData> => {
    let attempt = 0
    const maxAttempts = 2
    while (attempt < maxAttempts) {
      try {
        const headers: any = {
          Accept: 'application/json, text/plain, */*',
          'Accept-Encoding': 'gzip, deflate, br'
        }
        if (method === 'get') {
          // GET METHODS ARE NOT ALLOWED BODIES.
          data = null
        }
        if (data && !(data instanceof FormData)) {
          headers['Content-Type'] = 'application/json'
          if (data instanceof Array || data instanceof Object) {
            data = JSON.stringify(data)
          }
        }
        if (params) {
          const paramsArray: Array<any> = []
          Object.keys(params).forEach((e) => {
            let param = params[e]
            if (param instanceof Array || param instanceof Object) {
              param = JSON.stringify(param)
            }
            paramsArray.push([e, param])
          })
          url = `${url}?${new URLSearchParams(paramsArray)}`
        }
        const response = await fetch(url, {
          method: method,
          mode: 'cors',
          cache: 'no-cache',
          headers: headers,
          redirect: 'follow',
          referrerPolicy: 'strict-origin-when-cross-origin',
          body: data
        })
        if (!response.ok) {
          const contenttype = response?.headers?.get('Content-Type')
          if (contenttype && contenttype.indexOf('application/json') > -1) {
            const json = await response.json()
            if (json.error) {
              throw new Error(json.error)
            }
            if (json.message) {
              throw new Error(json.message)
            }
          } else if (
            contenttype &&
            (contenttype.indexOf('application/text') > -1 || contenttype.indexOf('text/plain'))
          ) {
            const text = await response.text()
            throw new Error(text)
          }
          throw new Error('Error connecting to API server.')
        }
        ;(response as ResponseWithData).data = await response.json()
        return response as ResponseWithData
      } catch (e: any) {
        attempt++
        if (attempt >= maxAttempts) {
          captureException(e)
          if (e && e.message && e.message === 'Failed to fetch') {
            const currentUrl = window.location.href
            if (currentUrl.includes('/kiosk/' || '/qrcode/')) {
              window.location.reload()
            }
          }
          this.errorMessages = []
          if (e.response && e.response.data) {
            if (e.response.data.message) {
              this.errorMessages.push(e.response.data.message)
            } else if (e.response.data.messages) {
              this.errorMessages = e.response.data.messages
            }
          } else {
            this.errorMessages.push(`${e.message}`)
          }
          if (Array.isArray(this.errorMessages) && this.errorMessages.length > 0) {
            if (this.errorMessages?.[0] === 'TypeError :: Failed to fetch') {
              throw this.errorMessages?.[0]
            } else {
              throw this.errorMessages?.[0]
            }
          }
          throw e
        }
      }
    }
  }

  getAuth = async (url: string, params: object): Promise<ResponseWithData> => {
    return await this.call(this.buildURL(url), 'get', params, {})
  }

  postAuth = async (url: string, data: object, params: {} = {}): Promise<ResponseWithData> => {
    return await this.call(this.buildURL(url), 'post', params, data)
  }

  putAuth = async (url: string, data: object): Promise<ResponseWithData> => {
    return await this.call(this.buildURL(url), 'put', {}, data)
  }

  deleteAuth = async (url: string): Promise<ResponseWithData> => {
    return await this.call(this.buildURL(url), 'delete', {}, {})
  }
}
