export enum FieldValidationType {
  Phone = 'Phone',
  Email = 'Email',
  Required = 'Required',
  MaxLength = 'MaxLength',
  MinLength = 'MinLength',
  MaxValue = 'MaxValue',
  MinValue = 'MinValue',
  Custom = 'Custom'
}
