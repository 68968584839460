<template>
  <div
    class="flex min-h-full flex-1 flex-col justify-center px-8 py-8 bg-gray-100 text-gray-800 w-full items-center gap-5"
  >
    <template v-if="$props.kiosk?.Organization?.logoUrl">
      <img :src="$props.kiosk?.Organization?.logoUrl" alt="Logo" class="max-h-40 max-w-40" />
    </template>
    <template v-else>
      <img :src="defaultLogo" alt="Logo" class="w-20" />
    </template>
    <h2 class="text-5xl font-bold leading-9 tracking-tight">Welcome</h2>
    <h2 class="text-3xl font-bold leading-9 tracking-tight">Need Help?</h2>
    <button
      :disabled="!buttonEnabled"
      @click="start"
      class="bg-blue-500 hover:bg-blue-600 text-white font-bold py-6 px-6 rounded-full border-4 border-white transition duration-300 ease-in-out shadow-md hover:shadow-lg w-32 h-32 flex items-center justify-center text-4xl"
    >
      Go
    </button>
    <div class="flex gap-4 sm:max-w-md flex-col"></div>
  </div>
</template>
<script setup lang="ts">
import defaultLogo from '@/assets/default-logo.webp'
import { type Ref, ref, onErrorCaptured } from 'vue'
import type { Kiosk } from 'types/Kiosk'
import { captureException } from '@/bubble';
defineProps<{ kiosk: Kiosk }>()
const emit = defineEmits<{
  (e: 'transition'): void
}>()
const buttonEnabled: Ref<boolean> = ref(true)
const start = () => {
  buttonEnabled.value = false
  emit('transition')
}
onErrorCaptured((err, vm, info) => {
  captureException(err);
})
</script>
<style scoped>
@tailwind base;
@tailwind components;
@tailwind utilities;
div {
  font-family: 'Inter Var', 'ui-sans-serif', 'system-ui', 'sans-serif';
}
</style>
