<template>
  <div
    ref="container"
    class="flex flex-col justify-center items-center overflow-hidden bg-no-repeat bg-contain bg-center bg-slate-900 relative video-container in-call-wrapper text-gray-800 max-w-full max-h-full"
    :class="{ windowed: $props.isWindowed }"
    :style="{
      backgroundImage: `url(${backgroundImage})`
    }"
  >
    <div class="flex gap-1 flex-col justify-start items-start top-1 left-1 absolute">
      <!-- User Connecting -->
      <div class="w-full flex gap-1" v-if="liveSwitchMeeting.meeting">
        <div
          class="size-3 rounded-full animate-pulse"
          :class="{
            'bg-green-500': (liveSwitchMeeting?.meeting?.state === 'joined'),
            'bg-yellow-600': (liveSwitchMeeting?.meeting?.state !== 'joined'),
          }"
        ></div>
        <div
          class="text-xs text-truncate"
          :class="{
            'text-green-500': (liveSwitchMeeting?.meeting?.state === 'joined'),
            'text-yellow-600': (liveSwitchMeeting?.meeting?.state !== 'joined')
          }"
        >
          {{ userName }} (<template v-if="liveSwitchMeeting?.meeting?.state === 'joined'">Connected.</template>
          <template v-else-if="liveSwitchMeeting?.meeting?.state === 'joining' || liveSwitchMeeting?.meeting?.state === 'new'">Connecting...</template>
          <template v-else>Reconnecting... </template>)
        </div>
      </div>
      <!-- /User Connecting -->
      <!-- Agent Connecting -->
      <div class="w-full flex flex-col gap-1" v-if="liveSwitchMeeting.meeting">
        <div class="flex gap-1">
          <div
            class="size-3 rounded-full animate-pulse"
            :class="{
              'bg-green-500': (liveSwitchMeeting?.meeting?.attendees?.count ?? 0) >= 2,
              'bg-yellow-500': (liveSwitchMeeting?.meeting?.attendees?.count ?? 0) < 2
            }"
          ></div>
          <div
            class="text-xs text-truncate"
            :class="{
              'text-green-500': (liveSwitchMeeting?.meeting?.attendees?.count ?? 0) >= 2,
              'text-yellow-500': (liveSwitchMeeting?.meeting?.attendees?.count ?? 0) < 2
            }"
          >
            {{ agentName }} (<template v-if="(liveSwitchMeeting?.meeting?.attendees?.count ?? 0) >= 2">Connected.</template>
            <template v-else-if="agentHasConnected">Waiting for the agent to reconnect.</template>
            <template v-else>Connecting...</template>)
          </div>
        </div>
      </div>
      <!-- / Agent Connecting -->
      <!-- Recording Status -->
      <div v-if="liveSwitchMeeting?.meeting?.isRecording && liveSwitchMeeting" class="mt-2 bg-red-500 p-1 rounded" @click='resetHideRecordingText()'>
        <div class="animate-pulse flex text-white">
          <v-icon class="icon-recording-circle"></v-icon>
          <span v-if="!hideRecordingText" :class="{ 'fade-out': hideRecordingText }" class="text-xs ml-1">Recording</span>
        </div>
      </div>
      <!-- Recording Status -->
    </div>
    <template v-for="media in remoteUserMedia" :key="media.id">
      <div class="flex justify-center align-middle remote-video w-32 h-24" :class="remoteVideoCSS">
        <video
          class="w-full h-full object-contain"
          webkit-playsinline
          @loadedmetadata="playViaEvent"
          @loadstart="playViaEvent"
          :ref="async (el: any) => await runVideoPlay(el)"
          v-if="media.videoTrack.stream"
          v-show="media && media.videoTrack && !media.videoTrack.isMuted"
          playsinline
          :srcObject.prop.camel="media.stream"
        ></video>
      </div>
    </template>
    <template v-for="media in remoteDisplayMedia" :key="media.id">
      <video
        class="remote-video max-w-full max-h-full screen liveswitch-remote-screenshare h-full"
        :ref="async (el: any) => await runVideoPlay(el)"
        @loadedmetadata="playViaEvent"
        @loadstart="playViaEvent"
        playsinline
        webkit-playsinline
        :srcObject.prop.camel="media.stream"
        @dblclick="fullscreenScreenShare"
      ></video>
    </template>
    <div
      class="absolute bg-transparent opacity-20 pointer-events-none top-0 left-0 right-0 bottom-0 justify-center items-center hover-remote-screenshare hidden text-white flex-col gap-2 text-2xl"
    >
      <i class="icon-fullscreen text-4xl"></i>
      <div><span>Double click for full screen.</span></div>
    </div>
    <div class="absolute top-1 right-1 flex flex-col justify-end gap-2 align-middle" :class="localVideoCSS">
      <video
        class="object-contain liveswitch-local-webcam-video w-full h-full"
        :class="{
          'scale-x-[-1]': liveSwitchMeeting.userMedia.videoTrack.facingMode !== 'environment'
        }"
        webkit-playsinline
        muted="true"
        @loadedmetadata="playViaEvent"
        @loadstart="playViaEvent"
        :ref="async (el: any) => await runVideoPlay(el)"
        v-if="liveSwitchMeeting.userMedia.videoTrack.stream"
        v-show="
          liveSwitchMeeting.userMedia &&
          liveSwitchMeeting.userMedia.videoTrack &&
          !liveSwitchMeeting.userMedia.videoTrack.isMuted
        "
        playsinline
        :srcObject.prop.camel="liveSwitchMeeting.userMedia.stream"
      ></video>
      <video
        class="local-video max-w-32 max-h-24 object-contain"
        muted="true"
        v-if="
          liveSwitchMeeting.displayMedia.videoTrack.stream &&
          liveSwitchMeeting.displayMedia.videoTrack.isStarted &&
          !liveSwitchMeeting.displayMedia.videoTrack.isMuted
        "
        :ref="async (el: any) => await runVideoPlay(el)"
        @loadedmetadata="playViaEvent"
        @loadstart="playViaEvent"
        playsinline
        webkit-playsinline
        :srcObject.prop.camel="liveSwitchMeeting.displayMedia.stream"
      ></video>
    </div>
    <div
      class="absolute bottom-1 flex gap-4 bg-gray-400/30 opacity-30 hover:opacity-90 p-3 rounded-lg left-1/2 -translate-x-1/2 w-auto text-center z-10"
    >
      <div class="group relative" v-if="liveSwitchMeeting?.meeting?.canSendUserMedia">
        <button
          class="size-12 text-white p-2 rounded-full focus:outline-none transition duration-300 text-2xl flex flex-col justify-center items-center"
          :class="{
            'bg-green-600 hover:bg-green-500': isSendingVideo,
            'bg-red-600  hover:bg-red-500': !isSendingVideo
          }"
          type="button"
          @click="liveSwitchMeeting.toggleVideoMuted"
        >
          <i
            :class="{
              'icon-video-slash-solid': !isSendingVideo,
              'icon-video-solid': isSendingVideo
            }"
          ></i>
        </button>
        <div
          class="absolute hidden group-hover:block bg-gray-800 text-white text-sm py-2 px-4 rounded shadow-lg bottom-full mb-2 -left-3 whitespace-nowrap"
        >
          {{ isSendingVideo ? 'Stop Sharing Webcam' : 'Start Sharing Webcam' }}
        </div>
      </div>
      <div class="group relative" v-if="liveSwitchMeeting?.meeting?.canSendUserMedia">
        <button
          class="size-12 text-white p-2 rounded-full focus:outline-none transition duration-300 text-2xl flex flex-col justify-center items-center"
          :class="{
            'bg-green-600 hover:bg-green-500': isSendingAudio,
            'bg-red-600  hover:bg-red-500': !isSendingAudio
          }"
          type="button"
          @click="liveSwitchMeeting.toggleAudioMuted"
        >
          <i
            :class="{
              'icon-audio-mic-slash-solid': !isSendingAudio,
              'icon-audio-mic-solid': isSendingAudio
            }"
          ></i>
        </button>
        <div
          class="absolute hidden group-hover:block bg-gray-800 text-white text-sm py-2 px-4 rounded shadow-lg bottom-full mb-2 left-1/2 transform -translate-x-1/2 whitespace-nowrap"
        >
          {{ isSendingAudio ? 'Stop Sharing Microphone' : 'Start Sharing Microphone' }}
        </div>
      </div>
      <div
        class="group relative"
        v-if="
          liveSwitchMeeting?.meeting?.canSendDisplayMedia && canShareScreen() && props.sourceType !== SourceType.KIOSK
        "
      >
        <button
          class="size-12 text-white p-2 rounded-full focus:outline-none transition duration-300 text-2xl flex flex-col justify-center items-center"
          :class="{
            'bg-green-600 hover:bg-green-500': isSendingScreenShare,
            'bg-red-600  hover:bg-red-500': !isSendingScreenShare
          }"
          type="button"
          @click="liveSwitchMeeting.toggleScreenShare"
        >
          <i
            :class="{
              'icon-screenshare-slash': !isSendingScreenShare,
              'icon-screenshare': isSendingScreenShare
            }"
          ></i>
        </button>
        <div
          class="absolute hidden group-hover:block bg-gray-800 text-white text-sm py-2 px-4 rounded shadow-lg bottom-full mb-2 left-1/2 transform -translate-x-1/2 whitespace-nowrap"
        >
          {{ isSendingScreenShare ? 'Stop Sharing Screen' : 'Start Sharing Screen' }}
        </div>
      </div>
      <div class="group relative" v-if="liveSwitchMeeting?.meeting?.canSendUserMedia">
        <button
          class="size-12 bg-yellow-600 hover:bg-yellow-500 text-white p-2 rounded-full focus:outline-none transition ease-in-out text-2xl flex flex-col justify-center items-center"
          type="button"
          @click="showSettingsArea = true"
        >
          <i class="icon-cog-gear"></i>
        </button>
        <div
          class="absolute hidden group-hover:block bg-gray-800 text-white text-sm py-2 px-4 rounded shadow-lg bottom-full mb-2 left-1/2 transform -translate-x-1/2 whitespace-nowrap"
        >
          Settings
        </div>
      </div>
      <div class="group relative">
        <button
          class="size-12 bg-red-600 hover:bg-red-500 text-white p-2 rounded-full focus:outline-none transition ease-in-out text-2xl flex flex-col justify-center items-center"
          type="button"
          @click="close"
        >
          <i class="icon-close-large"></i>
        </button>
        <div
          class="absolute hidden group-hover:block bg-gray-800 text-white text-sm py-2 px-4 rounded shadow-lg bottom-full mb-2 left-1/2 transform -translate-x-1/2 whitespace-nowrap"
        >
          End Call
        </div>
      </div>
    </div>
    <div
      v-if="showSettingsArea"
      class="absolute z-50 w-full h-full bg-black/30 flex justify-center items-center flex-col p-2"
    >
      <!-- The actual dialog panel -->
      <div
        class="relative transform overflow-auto rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6"
      >
        <i
          class="absolute top-2 right-2 icon-close-large cursor-pointer"
          @click="showSettingsArea = false"
        ></i>

        <!-- Video Settings -->
        <template v-if="liveSwitchMeeting?.meeting?.localUserMedia.videoTrack.isStarted">
          <p class="text-sm font-semibold leading-6 text-gray-900 mb-2">Video Devices</p>
          <div class="grid grid-cols-[auto,1fr,auto] gap-2 mb-4 text-sm" role="list">
            <template v-for="device in MediaDeviceManager.shared.videoInputs">
              <div
                class="flex items-center justify-center group"
                :class="{
                  selected:
                    liveSwitchMeeting?.meeting?.localUserMedia.videoDeviceId === device.id ||
                    liveSwitchMeeting?.meeting?.localUserMedia.requestedVideoDeviceId === device.id
                }"
              >
                <i class="icon-video-solid group-[.selected]:text-blue-400"></i>
              </div>
              <div
                class="cursor-pointer group"
                @click="liveSwitchMeeting?.meeting?.localUserMedia?.setVideoDevice(device.id, true)"
                @touchstart="liveSwitchMeeting?.meeting?.localUserMedia?.setVideoDevice(device.id, true)"
                :class="{
                  selected:
                    liveSwitchMeeting?.meeting?.localUserMedia.videoDeviceId === device.id ||
                    liveSwitchMeeting?.meeting?.localUserMedia.requestedVideoDeviceId === device.id
                }"
              >
                <span class="group-[.selected]:text-blue-400">{{ device.label }}</span>
              </div>
              <div class="flex items-center justify-center">
                <i
                  v-if="
                    liveSwitchMeeting?.meeting?.localUserMedia.videoDeviceId === device.id ||
                    liveSwitchMeeting?.meeting?.localUserMedia.requestedVideoDeviceId === device.id
                  "
                  class="icon-check-circle text-blue-400"
                ></i>
              </div>
            </template>
          </div>
        </template>

        <!-- Audio Settings -->
        <template v-if="liveSwitchMeeting?.meeting?.localUserMedia.audioTrack.isStarted">
          <p class="text-sm font-semibold leading-6 text-gray-900 mb-2">Microphone Devices</p>
          <div class="grid grid-cols-[auto,1fr,auto] gap-2 text-sm" role="list">
            <template v-for="device in MediaDeviceManager.shared.audioInputs">
              <div
                class="flex items-center justify-center group"
                :class="{
                  selected:
                    liveSwitchMeeting?.meeting?.localUserMedia.audioDeviceId === device.id ||
                    liveSwitchMeeting?.meeting?.localUserMedia.requestedAudioDeviceId === device.id
                }"
              >
                <i class="icon-audio-mic-solid group-[.selected]:text-blue-400"></i>
              </div>
              <div
                class="cursor-pointer group"
                @click="liveSwitchMeeting?.meeting?.localUserMedia?.setAudioDevice(device.id, true)"
                @touchstart="liveSwitchMeeting?.meeting?.localUserMedia?.setAudioDevice(device.id, true)"
                :class="{
                  selected:
                    liveSwitchMeeting?.meeting?.localUserMedia.audioDeviceId === device.id ||
                    liveSwitchMeeting?.meeting?.localUserMedia.requestedAudioDeviceId === device.id
                }"
              >
                <span class="group-[.selected]:text-blue-400">{{ device.label }}</span>
              </div>
              <div class="flex items-center justify-center">
                <i
                  v-if="
                    liveSwitchMeeting?.meeting?.localUserMedia.audioDeviceId === device.id ||
                    liveSwitchMeeting?.meeting?.localUserMedia.requestedAudioDeviceId === device.id
                  "
                  class="icon-check-circle text-blue-400"
                ></i>
              </div>
            </template>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import type { UserDetails } from 'types/UserDetails'
import type { BubbleConfig } from 'types/BubbleConfig'
import type { Conversation } from 'types/Conversation'
import {
  type Ref,
  ref,
  onBeforeMount,
  type ComputedRef,
  computed,
  onMounted,
  onBeforeUnmount,
  onErrorCaptured,
  reactive,
  type Reactive,
  watch
} from 'vue'
import { LiveswitchMeeting } from '@/scripts/LiveSwitchMeeting'
import { Media, type Meeting, MediaDeviceManager, type MeetingAttendeeEvent } from '@liveswitch/sdk'
import { SourceType } from '@/utility/Constants'
import defaultLogo from '@/assets/default-logo.webp'
import { UAParser } from 'ua-parser-js'
import { Session } from '@/scripts/Session'
import type { LastActiveCall } from 'types/LastActiveCall'
import { API } from '@/utility/Api'
import type { Organization } from 'types/Organization'
import { captureException } from '@/bubble'
import type DevicesEvent from 'node_modules/@liveswitch/sdk/media/models/DevicesEvent'

let container: Ref<any> = ref()
const agentHasConnected: Ref<boolean> = ref(false)
const isMobile = () => {
  const { device } = UAParser(navigator.userAgent)
  return !!device.type // Desktops never have a type.
}
const backgroundImage = ref(defaultLogo);
const hideRecordingText = ref(false);

const canShareScreen = () => {
  return navigator.mediaDevices && navigator.mediaDevices.getDisplayMedia
}

const props = defineProps<{
  config: BubbleConfig
  userDetails?: UserDetails
  conversation: Conversation
  isWindowed: boolean
  sourceType: SourceType
}>()

Session.setSession(props as LastActiveCall)

const emits = defineEmits<{
  (e: 'save', user: UserDetails): UserDetails
  (e: 'close'): void
}>()

const liveSwitchMeeting: Reactive<LiveswitchMeeting> = reactive(new LiveswitchMeeting(
  props.conversation,
  props.sourceType === SourceType.KIOSK || props.sourceType === SourceType.QRCODE,
  props.userDetails
))

const showSettingsArea: Ref<boolean> = ref(false)

onMounted(async () => {
  const api = new API()
  const resp = await api.getAuth(`/public/organizations/${props.conversation.organizationId}`, {});
  const org: Organization = resp.data as Organization;
  if (org && org.logoUrl) { 
    backgroundImage.value = org.logoUrl;
  }
  const meeting = await liveSwitchMeeting.start(
    container.value as HTMLDivElement
  )
  meeting?.attendeeJoined.bind(async (args: MeetingAttendeeEvent) => {
    if (args.attendee.isRemote) {
      agentHasConnected.value = true
    }
  })
})

function resetHideRecordingText() {
			hideRecordingText.value = false;
			setTimeout(() => {
				hideRecordingText.value = true;
			}, 2500);
		}

watch(
  () => liveSwitchMeeting?.meeting?.isRecording,
  (newVal) => {
    if (newVal === true) {
      resetHideRecordingText()
    } else {
      hideRecordingText.value = false;
    }
  }
);

onBeforeMount(async () => {
  try {
    if (!MediaDeviceManager.shared.isStarted && !MediaDeviceManager.shared.isStarting) {
      await MediaDeviceManager.shared.start()
    }
    /* 
      Attempts to detect if the currently in use device was unplugged. If so it will switch to another device or mute the track if no other device exists.
    */
    const tryAndSwitchDeviceAfterUnplug = async (type: "video" | "audio", e : DevicesEvent) : Promise<void> => {
      const currentDeviceId = type === "video" ? liveSwitchMeeting?.meeting?.localUserMedia?.videoDeviceId : liveSwitchMeeting?.meeting?.localUserMedia?.audioDeviceId;
      if (!currentDeviceId) {
        return;
      }
      for (let i = 0, l = e.removed.length; i < l; i++) {
        const device = e.removed[i];
        if (device && currentDeviceId === device.id) {
          if (type === "video") { 
            if (e.manager.videoInputs.length > 0) {
              try {
                const newVideoInput = e.manager.videoInputs[0];
                await liveSwitchMeeting?.meeting?.localUserMedia?.setVideoDevice(newVideoInput.id, true);
              } catch (e) {
                captureException(e);
              }
            } else {
              await liveSwitchMeeting?.toggleVideoMuted();
            }
          } else {
            if (e.manager.audioInputs.length > 0) {
              try {
                const newAudioInput = e.manager.audioInputs[0];
                await liveSwitchMeeting?.meeting?.localUserMedia?.setAudioDevice(newAudioInput.id, true);
              } catch (e) {
                captureException(e);
              }
            } else {
              await liveSwitchMeeting?.toggleAudioMuted();
            }
          }
        }
      }
    }
    MediaDeviceManager.shared.videoInputsUpdated.bind(async (e) => { 
      await tryAndSwitchDeviceAfterUnplug("video", e);
    });
    MediaDeviceManager.shared.audioInputsUpdated.bind(async (e) => {
      await tryAndSwitchDeviceAfterUnplug("audio", e);
    });
  } catch (e) {
    captureException(e);
  }
})

// // UI Computeds
const userName: ComputedRef<string> = computed<string>(() => {
  const name = []
  if (props.userDetails?.firstName) {
    name.push(props.userDetails.firstName)
  }
  if (props.userDetails?.lastName) {
    name.push(props.userDetails.lastName)
  }
  if (name.length === 0) {
    name.push('Visitor (you)')
  }
  return name.join(' ')
})
const agentName: ComputedRef<string> = computed<string>(() => {
  if (props.conversation && props.conversation.Users && props.conversation.Users.length > 0) {
    const name = []
    const user = props.conversation.Users[0]
    if (user.firstname) {
      name.push(user.firstname)
    }
    if (user.lastname) {
      name.push(user.lastname)
    }
    return name.join(' ')
  }
  return 'Agent'
})
const remoteDisplayMedia: ComputedRef<Media[]> = computed<Media[]>(() => {
  return liveSwitchMeeting?.meeting?.visibleDisplayMedias.filter((x: Media) => x.isRemote) ?? []
})
const remoteUserMedia: ComputedRef<Media[]> = computed<Media[]>(() => {
  return liveSwitchMeeting?.meeting?.visibleUserMedias.filter((x: Media) => x.isRemote) ?? []
})
const isSendingAudio: ComputedRef<boolean> = computed<boolean>(() => {
  return !liveSwitchMeeting.userMedia.audioTrack.isMuted
})
const isSendingVideo: ComputedRef<boolean> = computed<boolean>(() => {
  return (
    !liveSwitchMeeting?.userMedia.videoTrack.isMuted &&
    liveSwitchMeeting?.userMedia.videoTrack.isStarted
  )
})
const isSendingScreenShare: ComputedRef<boolean> = computed<boolean>(() => {
  return liveSwitchMeeting.displayMedia.videoTrack.isStarted
})

const remoteVideoCSS: ComputedRef<string> = computed<string>(() => { 
  if (remoteUserMedia.value.length === 0 || remoteUserMedia.value[0].videoTrack.isMuted) {
    return "";
  }
  if (remoteDisplayMedia.value.length === 0) { 
    return "max-w-full max-h-full h-full w-full bg-slate-900";
  }
  else {
    if (!liveSwitchMeeting.userMedia || !liveSwitchMeeting.userMedia.videoTrack || liveSwitchMeeting.userMedia.videoTrack.isMuted) {
      return "absolute right-1 max-h-24 top-1 max-w-32 bg-black"
    } else {
      return 'absolute right-1 max-h-24 top-28 max-w-32 bg-black';
    }
  }
});
const localVideoCSS: ComputedRef<string> = computed<string>(() => { 
  if (!liveSwitchMeeting.userMedia || !liveSwitchMeeting.userMedia.videoTrack || liveSwitchMeeting.userMedia.videoTrack.isMuted) {
    return "";
  }
  if (remoteDisplayMedia.value.length > 0) {
    return "bg-gray-500 w-32 h-24"
  }
  if (isSendingScreenShare) {
    return "w-32 h-32"; 
  }
  return "w-32 h-24";
})
const fullscreenScreenShare = (ev: MouseEvent) => {
  const video: HTMLVideoElement = ev.target as HTMLVideoElement
  if (
    !(
      (
        document.fullscreenElement /* Standard syntax */ ||
        (document as any).webkitFullscreenElement /* Safari and Opera syntax */ ||
        (document as any).msFullscreenElement
      ) /* IE11 syntax */
    ) &&
    video
  ) {
    if (video.requestFullscreen) {
      video.requestFullscreen()
    } else if ((video as any).webkitRequestFullscreen) {
      /* Safari */
      ;(video as any).webkitRequestFullscreen()
    } else if ((video as any).msRequestFullscreen) {
      /* IE11 */
      ;(video as any).msRequestFullscreen()
    }
  } else {
    if (document.exitFullscreen) {
      document.exitFullscreen()
    } else if ((document as any).webkitExitFullscreen) {
      /* Safari */
      ;(document as any).webkitExitFullscreen()
    } else if ((document as any).msExitFullscreen) {
      /* IE11 */
      ;(document as any).msExitFullscreen()
    }
  }
}
onBeforeUnmount(async () => {
  try {
    await liveSwitchMeeting.stop()
  } catch (e) {
    captureException(e);
  }
})
const close = async () => {
  try {
    await liveSwitchMeeting.stop()
  } catch (e) {
    captureException(e);
  }
  emits('close')
}
const runVideoPlay = async (el: HTMLVideoElement) => {
  if (el) {
    try {
      await el.play()
    } catch (e) {
      captureException(e);
    }
  }
}
const playViaEvent = async (ev: Event) => {
  try {
    if (ev && ev.target) {
      await (ev.target as HTMLVideoElement).play()
    }
  } catch (e) {
    captureException(e);
  }
}
onErrorCaptured((err, vm, info) => {
  captureException(err);
})
</script>
<style scoped>
@import url('/src/styles/icons.css');
@tailwind base;
@tailwind components;
@tailwind utilities;
@media (pointer: fine) {
  div.video-container > video.liveswitch-remote-screenshare:hover + div.hover-remote-screenshare {
    display: flex;
  }
}
div.video-container:has(video.remote-video:not([style*='display: none;'])) {
  background-size: 0;
}
div {
  font-family: 'Inter Var', 'ui-sans-serif', 'system-ui', 'sans-serif';
}
@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
.fade-out {
  animation: fadeOut 1s forwards;
}
.text-truncate {
  white-space: nowrap;
}
</style>
