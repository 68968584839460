import type { SentryScope } from '@/bubble'
import * as Sentry from '@sentry/browser'
import { sentryTag } from './Constants'

export interface SentryClient {
  init(): any
}

const sharedVariables = {
  dsn: 'https://fd0262ce954642cf896fcf1c0d5185e0@o4504596163002368.ingest.us.sentry.io/4504686451621888',
  tracePropagationTargets: [
    'localhost',
    /^https:\/\/api\.testing\.liveswitch\.com/,
    /^https:\/\/api\.contact\.testing\.liveswitch\.com/,
    /^https:\/\/api\.contact\.staging\.liveswitch\.com/,
    /^https:\/\/api\.staging\.liveswitch\.com/,
    /^https:\/\/api\.demo\.liveswitch\.com/,
    /^https:\/\/api\.contact\.demo\.liveswitch\.com/,
    /^https:\/\/api\.contact\.liveswitch\.com/,
    /^https:\/\/api\.production\.liveswitch\.com/,
    /^https:\/\/api\.liveswitch\.com/,
    /^https:\/\/jake-api\.ngrok\.app/,
    /^https:\/\/jake-socket\.ngrok\.app/,
    /^https:\/\/ls-concierge-socket-staging\.herokuapp\.com/,
    /^https:\/\/ls-concierge-socket-develop\.herokuapp\.com/,
    /^https:\/\/socket\.concierge\.liveswitch\.com/
  ],
  maxBreadcrumbs: 100,
  maxValueLength: 500,
  normalizeDepth: 10,
  normalizeMaxBreadth: 1000,
  sendDefaultPii: true,
  attachStacktrace: true,
  autoSessionTracking: true
}

class SharedSentry implements SentryClient {
  init(): SentryScope {
    Sentry.init({
      dsn: sharedVariables.dsn,
      maxBreadcrumbs: sharedVariables.maxBreadcrumbs,
      sendDefaultPii: sharedVariables.sendDefaultPii,
      release: process.env.APP_VERSION,
      environment: process.env.NODE_ENV,
      attachStacktrace: sharedVariables.attachStacktrace,
      autoSessionTracking: sharedVariables.autoSessionTracking,
      maxValueLength: sharedVariables.maxValueLength,
      normalizeDepth: sharedVariables.normalizeDepth,
      normalizeMaxBreadth: sharedVariables.normalizeMaxBreadth,
      integrations: [
        Sentry.browserProfilingIntegration(),
        Sentry.browserTracingIntegration(),
        Sentry.captureConsoleIntegration({ levels: ['error'] }),
        Sentry.contextLinesIntegration(),
        Sentry.extraErrorDataIntegration(),
        Sentry.httpClientIntegration(),
        Sentry.reportingObserverIntegration(),
        Sentry.sessionTimingIntegration()
      ],
      tracePropagationTargets: sharedVariables.tracePropagationTargets
    })
    return Sentry
  }
}

class IsolatedSentry implements SentryClient {
  init(): SentryScope {
    const integrations = Sentry.getDefaultIntegrations({}).filter((defaultIntegration) => {
      return !['BrowserApiErrors', 'Breadcrumbs', 'GlobalHandlers'].includes(
        defaultIntegration.name
      )
    })
    const client = new Sentry.BrowserClient({
      dsn: sharedVariables.dsn,
      transport: Sentry.makeFetchTransport,
      stackParser: Sentry.defaultStackParser,
      integrations: integrations,
      maxBreadcrumbs: sharedVariables.maxBreadcrumbs,
      sendDefaultPii: sharedVariables.sendDefaultPii,
      release: process.env.APP_VERSION,
      environment: process.env.NODE_ENV,
      attachStacktrace: sharedVariables.attachStacktrace,
      autoSessionTracking: sharedVariables.autoSessionTracking,
      maxValueLength: sharedVariables.maxValueLength,
      normalizeDepth: sharedVariables.normalizeDepth,
      normalizeMaxBreadth: sharedVariables.normalizeMaxBreadth,
      tracePropagationTargets: sharedVariables.tracePropagationTargets,
      beforeSend: (event) => {
        // Check if the event exists and has tags
        if (!event || !event.tags) {
          return null
        }

        // Check if the 'source' tag is 'LiveSwitch-Concierge'
        if (event.tags['source'] !== sentryTag) {
          return null
        }

        // Return the event if all checks pass
        return event
      }
    })
    const scope = new Sentry.Scope()
    scope.setClient(client)
    client.init() // initializing has to be done after setting the client on the scope
    return scope
  }
}

export class SentryFactory {
  static createSentry(): SentryClient {
    const hostname = window.location.hostname
    if (hostname.endsWith('.liveswitch.com') || hostname === 'liveswitch.com') {
      return new SharedSentry()
    } else {
      return new IsolatedSentry()
    }
  }
}
