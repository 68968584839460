<template>
  <div
    class="flex min-h-full flex-1 flex-col justify-center px-8 py-8 bg-white text-green-800 w-full items-center"
  >
    <div class="flex gap-4 sm:max-w-md flex-col">
      <div class="flex-shrink-0 self-center">
        <i class="text-6xl icon-check-big text-green-500" />
      </div>
      <div class="flex flex-col">
        <h2 class="text-2xl font-bold leading-9 tracking-tight text-gray-900">
          {{ $props.config?.templateEnded ??  "Thank you for chatting with us." }}
        </h2>
        <p class="mt-4 self-center" v-if="sourceType === SourceType.KIOSK">
          <button
            class="rounded-full bg-indigo-600 px-4 py-2.5 font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 text-2xl"
            @click="$emit('close')"
          >
            Back To Start
          </button>
        </p>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { SourceType } from '@/utility/Constants'
import { captureException } from '@sentry/vue';
import type { BubbleConfig } from 'types/BubbleConfig'
import { onMounted, onErrorCaptured, onBeforeUnmount } from 'vue'
const props = defineProps<{
  config: BubbleConfig
  isWindowed: boolean
  sourceType: SourceType
}>()
const emit = defineEmits<{
  (e: 'close'): void
}>()
var time: any
const resetToHomepageOnInactivity = () => {
  window.location.reload()
}
const resetTimer = () => {
  clearTimeout(time)
  time = setTimeout(resetToHomepageOnInactivity, 60000)
}
const addEventListeners = () => {
  document.addEventListener('mousemove', resetTimer)
  document.addEventListener('mousedown', resetTimer) // touchscreen presses
  document.addEventListener('touchstart', resetTimer)
  document.addEventListener('click', resetTimer) // touchpad clicks
  document.addEventListener('keydown', resetTimer) // onkeypress is deprecated
  document.addEventListener('scroll', resetTimer, true) // improved; see comments
}
const removeEventListeners = () => {
  document.removeEventListener('mousemove', resetTimer)
  document.removeEventListener('mousedown', resetTimer) // touchscreen presses
  document.removeEventListener('touchstart', resetTimer)
  document.removeEventListener('click', resetTimer) // touchpad clicks
  document.removeEventListener('keydown', resetTimer) // onkeypress is deprecated
  document.removeEventListener('scroll', resetTimer, true) // improved; see comments
}
onMounted(() => {
  if (props.sourceType === SourceType.KIOSK) {
    resetTimer()
    addEventListeners()
  }
})
onBeforeUnmount(() => {
  clearTimeout(time)
  removeEventListeners()
})
onErrorCaptured((err, vm, info) => {
  captureException(err);
})
</script>
<style scoped>
@tailwind base;
@tailwind components;
@tailwind utilities;
div {
  font-family: 'Inter Var', 'ui-sans-serif', 'system-ui', 'sans-serif';
}
</style>
