import { captureException } from '@/bubble'
import { io, Socket } from 'socket.io-client'
import type { Conversation } from 'types/Conversation'

export interface ServerToClientEvents {
  conversation_update: (data: { conversation: Conversation }) => void
}

export interface ClientToServerEvents {}

export class SocketHandler extends EventTarget {
  socket: Socket<ServerToClientEvents, ClientToServerEvents> | undefined = undefined
  private eventListeners: {
    eventName: keyof ServerToClientEvents
    callback: ServerToClientEvents[keyof ServerToClientEvents]
  }[] = []

  constructor() {
    super()
  }

  connect(
    visitorId: string,
    callbackFn: (socket: Socket<ServerToClientEvents, ClientToServerEvents>) => void
  ) {
    const url = import.meta.env.VITE_SOCKET_URL
    this.disconnect()

    this.socket = io(`${url}/beacon`, {
      transports: ['websocket'],
      autoConnect: true,
      reconnection: true,
      auth: {
        visitorId
      }
    })

    this.socket.on('connect', () => {
      try {
        if (this.socket) {
          callbackFn.call(this, this.socket)
        }
        this.dispatchEvent(
          new CustomEvent('connected', {
            detail: { socket: this.socket, handler: this }
          })
        )
      } catch (e) {
        captureException(e)
      }
    })

    this.socket.on('disconnect', () => {
      try {
        this.dispatchEvent(
          new CustomEvent('disconnect', {
            detail: { socket: this.socket, handler: this }
          })
        )
      } catch (e) {
        captureException(e)
      }
    })

    this.socket.io.on('reconnect', () => {
      try {
        this.dispatchEvent(
          new CustomEvent('reconnect', {
            detail: { socket: this.socket, handler: this }
          })
        )
      } catch (e) {
        captureException(e)
      }
    })

    this.socket.on('connect_error', () => {
      try {
        this.dispatchEvent(
          new CustomEvent('disconnect', {
            detail: { socket: this.socket, handler: this }
          })
        )
      } catch (e) {
        captureException(e)
      }
    })

    this.socket.on('conversation_update', ({ conversation }) => {
      try {
        this.dispatchEvent(
          new CustomEvent<Conversation>('conversation_update', {
            detail: conversation
          })
        )
      } catch (e) {
        captureException(e)
      }
    })
  }

  sendEvent<K extends keyof ClientToServerEvents>(
    eventName: K,
    ...data: Parameters<ClientToServerEvents[K]>
  ) {
    try {
      if (!this.socket) {
        throw new Error('You must call connect before sending events.')
      }
      this.socket.emit(eventName, ...data)
    } catch (e) {
      captureException(e)
    }
  }

  disconnect() {
    try {
      if (this.socket) {
        this.socket.removeAllListeners()
        this.socket.disconnect()
      }
    } catch (e) {
      captureException(e)
    }
  }
}
