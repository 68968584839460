<template>
  <div class="flex min-h-full flex-1 flex-col px-8 py-8 bg-white text-gray-800 w-full items-center">
    <div class="flex gap-4 sm:max-w-md flex-col">
      <div class="flex-shrink-0 self-center">
        <i class="text-4xl icon-audio-mic-solid text-green-600" />
      </div>
      <div>
        <h2 class="text-2xl font-bold leading-9 tracking-tight text-gray-900">
          <template v-if="videoPermissionReq">Camera and </template>Microphone Access Required
        </h2>
        <p class="mt-1">
          LiveSwitch Concierge requires access to your <template v-if="videoPermissionReq">camera and </template>microphone. Please <span class="text-green-600"> allow <span v-if="videoPermissionReq">camera and </span>microphone access </span> from site settings to continue.
        </p>
        <p class="mt-2" v-if="mediaError">
          <span class="text-red-600 text-xs">Issue: {{ mediaError }}</span
          ><br />
          <button
            @click="checkPermission"
            class="mt-4 w-full rounded-full bg-indigo-600 px-4 py-2.5 text-md font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
          Retry <template v-if="videoPermissionReq">Camera and </template>Microphone Access
          </button>
        </p>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { captureException } from '@/bubble';
import { SourceType } from '@/utility/Constants'
import { UserMedia } from '@liveswitch/sdk'
import { type Ref, ref, onMounted, onErrorCaptured } from 'vue'
const props = defineProps<{
  sourceType: SourceType
}>()
const emits = defineEmits<{
  (e: 'transition'): void
}>()
const mediaError: Ref<string> = ref('')
const videoPermissionReq = ref(false);

const checkPermission = async () => {
  try {
    const userMedia = new UserMedia(
      true,
      videoPermissionReq.value
    )
    await userMedia.start()
    await userMedia.stop()
    mediaError.value = ''
    emits('transition')
  } catch (e) {
    mediaError.value = (e as any).message ?? ''
  }
}
onMounted(async () => {
  videoPermissionReq.value = props.sourceType === SourceType.KIOSK || props.sourceType === SourceType.QRCODE
  await checkPermission()
})
onErrorCaptured((err, vm, info) => {
  captureException(err);
})
</script>
<style scoped>
@tailwind base;
@tailwind components;
@tailwind utilities;
div {
  font-family: 'Inter Var', 'ui-sans-serif', 'system-ui', 'sans-serif';
}
</style>
