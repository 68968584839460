import type { CallState } from './LiveSwitchMeeting'
import type { LastActiveCall } from 'types/LastActiveCall'

export class Session {
  static activeCallKey: string = 'concierge-active-call'
  static callStateKey: string = 'concierge-call-state'

  static setSessionVariable(key: string, value: any) {
    try {
      sessionStorage.setItem(key, JSON.stringify(value))
    } catch (e) {
      /* Some browsers have sessionStorage blocked or disabled. Nothing to do here, reconnection won't be supported. */
    }
  }

  static getSessionVariable(key: string): any | undefined {
    try {
      const propsJson = sessionStorage.getItem(key)
      if (propsJson) {
        return JSON.parse(propsJson)
      }
    } catch (e) {
      /* Some browsers have sessionStorage blocked or disabled. Nothing to do here, reconnection won't be supported. */
    }
    return undefined
  }

  static removeSessionVariable(key: string) {
    try {
      sessionStorage.removeItem(key)
    } catch (e) {
      /* Some browsers have sessionStorage blocked or disabled. Nothing to do here, reconnection won't be supported. */
    }
  }

  static setSession(session: LastActiveCall) {
    this.setSessionVariable(Session.activeCallKey, session)
  }

  static getSession(): LastActiveCall | undefined {
    return this.getSessionVariable(Session.activeCallKey)
  }

  static removeExistingSession() {
    this.removeSessionVariable(Session.activeCallKey)
  }

  static setCallState(state: CallState) {
    this.setSessionVariable(Session.callStateKey, state)
  }

  static getCallState(conversationId: string): CallState | undefined {
    const state = this.getSessionVariable(Session.callStateKey)
    if (!state) {
      return undefined
    }
    if (state.conversationId === conversationId) {
      return state
    } else {
      this.removeCallState()
      return undefined
    }
  }

  static removeCallState() {
    this.removeSessionVariable(Session.callStateKey)
  }
}
