<template>
  <div
    class="flex flex-col gap-1"
    ref="wrapper"
    :class="{
      'items-end': $props.config.position === 'right',
      'items-start': $props.config.position === 'left'
    }"
  >
    <div
      class="bubble items-center justify-center text-center size-16 grid grid-flow-row auto-rows-auto auto-cols-auto rounded-full cursor-pointer shadow-lg"
      :style="{ backgroundColor: $props.config.beaconBGColor }"
      @click="startBell"
    >
      <template v-if="isBackgroundWhite">
        <img class="size-8 mb-1" :src="LogoGold" />
      </template>
      <template v-else>
        <img class="size-8 mb-1" :src="LogoWhite" />
      </template>
    </div>
  </div>
</template>
<script setup lang="ts">
import type { BubbleConfig } from '../../types/BubbleConfig'
import { type ComputedRef, type Ref, computed, ref, onMounted, onErrorCaptured } from 'vue'
import { BellPopup, captureException } from '@/bubble'
import LogoGold from '../assets/logo-gold.svg'
import LogoWhite from '../assets/logo-white.svg'
const props = defineProps<{
  config: BubbleConfig
}>()
const emit = defineEmits<{
  (e: 'start'): void
}>()
const isButtonDisabled = ref(false)
let wrapper: Ref<HTMLDivElement | undefined> = ref(undefined)
let bellPopupCe: HTMLElement | undefined = undefined
let bellPopupTimeout: NodeJS.Timeout | undefined = undefined
const isBackgroundWhite: ComputedRef<boolean> = computed(() => {
  return (
    props.config.beaconBGColor.toUpperCase() === '#FFFFFF' ||
    props.config.beaconBGColor.toUpperCase() === '#FFF'
  )
})
const startBell = () => {
  if (!isButtonDisabled.value) {
    isButtonDisabled.value = true
    setTimeout(() => {
      isButtonDisabled.value = false
    }, 1000)
    if (bellPopupTimeout) {
      clearTimeout(bellPopupTimeout)
    }
    emit('start')
  }
}
onMounted(() => {
  bellPopupTimeout = setTimeout(() => {
    const bellPopupCE = new BellPopup({ config: props.config })
    bellPopupCe = bellPopupCE
    bellPopupCE.addEventListener('closePopup', () => {
      bellPopupCE.remove()
    })
    bellPopupCE.addEventListener('start', () => {
      emit('start')
    })
    wrapper.value?.prepend(bellPopupCE)
  }, 1)
})
onErrorCaptured((err, vm, info) => {
  captureException(err);
})
</script>
<style scoped>
@tailwind base;
@tailwind components;
@tailwind utilities;
</style>
